import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import TestimonalData from "../../constants/TestimonalData";
import { darkblue, _darkScheme } from "../../styles/colours";
import { Flex, FlexChild } from "../../styles/blocks";
import { Element } from "react-scroll";
import { _float, _center } from "../../styles/display";
import { Link } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 30px 0;
  box-sizing: border-box;
  margin: 100px 0 50px;
  position: relative;
`;

const SliderContainer = styled.div`
  position: relative;
  height: 220px;
`;

const Slider = styled(Flex)`
  transform: translateX(${p => p.slidePos}%);
  transition: 2s;
  position: absolute;
  top: 0;
  left: 0;
`;

const StonesContainer = styled(Flex)`
  width: 95%;
  max-width: 600px;
  margin: 50px auto 50px;
`;

const Stone = styled(Flex)`
  width: ${p => (p.large ? "20px" : "10px")};
  height: ${p => (p.large ? "20px" : "10px")};
  border-radius: 50%;
  background-color: ${p => (p.active ? "white" : darkblue)};
  cursor: pointer;
  border: ${p =>
    p.active ? `3px solid ${darkblue}` : `3px solid transparent`};
  transition: all 0.3s;
  box-sizing: content-box;
`;

const StoneChild = styled(FlexChild)`
  @media (max-width: 400px) {
    display: none;
  }

    display: flex;
    justify-content: center;
    align-items: center;

`;

const Step = styled.div`
  background-color: ${darkblue};
  padding: 1px;
  flex: 1;
`;

const Testimonial = styled.div`
  flex: 1;
  padding: 10px 60px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 5px 10px rgba(30, 30, 30, 0.4);
  min-width: 400px;
  max-width: 400px;
  margin: 0 50px;

  @media (max-width: 500px) {
    padding: 10px 30px;
    margin: 0;
    min-width: 100vw;
    max-width: 100vw;
  }
`;

const Info = styled(Flex)`
  height: 100%;
`;

const Text = styled.p`
  font-style: italic;
  font-size: 0.9rem;
  text-align: center;
  line-height: 2;
  font-weight: 100;
  font-family: "Roboto Slab";
  position: relative;
  margin: auto;
`;

const Role = styled.p`
  font-size: 0.75rem;
  margin: 5px;
`;
const Name = styled.p`
  font-weight: 800;
  margin: 5px;
`;

const SpeechMark = styled.img`
  max-width: 15px;
  position: absolute;
  color: ${darkblue};
  ${p =>
    p.pos
      ? `
    top: 0;
    left: -25px;
  `
      : `
    bottom: 0;
    right: -25px;
    transform: rotate(180deg);
  `}
`;

const I = styled.i`
  display: block;
  color: ${darkblue};
  font-size: 2rem;
  cursor: pointer;
  margin: auto;
`;

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const Stones = ({ card, setCard }) => (
  <StonesContainer key="cards" align padding="10px">
    <I onClick={() => setCard(card - 1)} className="fas fa-chevron-left"></I>
    {TestimonalData.map((_, i) => [
      <StoneChild width={1} key={"stone" + i}>
        <Flex justify="center">
          <Stone onClick={() => setCard(i)} active={i === card} />
        </Flex>
      </StoneChild>,
      ...(i !== TestimonalData.length - 1
        ? [
            <StoneChild width={1} key={"step" + i}>
              <Step />
            </StoneChild>
          ]
        : [])
    ])}
    <I
      onClick={() => setCard(card < TestimonalData.length - 1 ? card + 1 : 0)}
      className="fas fa-chevron-right"
    ></I>
  </StonesContainer>
);

const StyButton = styled.button`
  text-align: center;
  display: block;
  padding: 15px;
  font-size: 1.25rem;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 10px 20px -8px rgba(0,0,0,0.5);
  ${_darkScheme}
  ${_float}
  ${_center}
  margin: auto;
`;

const StyLink = styled(Link)`
  &:link {
    text-decoration: none;
  }
`;

export default function() {
  const [slidePos, setSlidePos] = useState(0);

  // const moveSlider = () => slidePos < TestimonalData.length ? setSlidePos(slidePos + 1) : 0;
  // useInterval(moveSlider, 5000);

  return (
    <Element name={"testimonials"} key={"testimonials"} id={"testimonials"}>
      <Container>
        <SliderContainer>
          <Slider slidePos={-(slidePos / TestimonalData.length) * 100}>
            {TestimonalData.map(
              testimonial =>
                testimonial.caption && (
                  <Testimonial key={testimonial.caption}>
                    <Info col justify="space-between">
                      <Text>
                        <SpeechMark src="/images/sp_m.png" pos={1} />
                        <SpeechMark src="/images/sp_m.png" pos={0} />
                        {testimonial.caption}
                      </Text>
                      <div>
                        <Name>{testimonial.person}</Name>
                        <Role>{testimonial.role}</Role>
                      </div>
                    </Info>
                  </Testimonial>
                )
            )}
          </Slider>
        </SliderContainer>
        <Stones card={slidePos} setCard={setSlidePos} />
        <StyLink to="testimonials">
          <StyButton onClick={() => window.scrollTo(0, 0)}>
            View Testimonials
          </StyButton>
        </StyLink>
      </Container>
    </Element>
  );
}
