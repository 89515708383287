import React from 'react';
import styled from 'styled-components';

import { S3 } from '../../constants/S3';

const Img = styled.img`
  float: right;
  margin-left: 15px;
  margin-bottom: 10px;
  width: 400px;

  @media (max-width: 1000px) {
    display: block;
    width: 90%;
    margin: 0 auto 20px;
    float: none;
  }
`;

const Executive = () =>
  <div>
    <p>
      <Img src={`/images/practices/min/executive.jpg`} />
      Most top executives would acknowledge the value of knowing themselves better, having higher
      emotional IQ and achieving a greater balance in their lives, but how many invest in doing so?
    </p>
    <p>
      Our approach to executive coaching allows the executive to look at themselves from multiple
      perpectives and in depth, improving their awareness of their inner landscape and its impact. We
      help them evaluate their emotional intelligence and how it bears on their performance.
      We encourage our clients to zoom out, to take stock of their life goals, look at the quality of their
      life, set realistic boundaries and enrich their experience of life, finding gratitude and joy.
    </p>
  </div>;

export default Executive;
