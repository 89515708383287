
export const TopLayer = [
  {
    page: 'We Believe',
    caption: `We believe that people and organisations can change for the better. We are here to help coachees clarify their thinking, be more aware of their impact on others and live more satisfying, fulfilling and productive lives.`,
    link: 'we-believe',
    image: `/images/home/min/believe.jpg`,
  },
];

export const BottomLayer = [
  {
    page: 'Our Coaches',
    caption: `All members of our team are both experienced coaching practitioners and have held down jobs at
              senior management level in either the public or private sectors. This means they can both empathise
              with the coachee and help them look awry at the systems within which they operate.`,
    link: 'our-coaches',
    image: `/images/home/min/coaches.jpg`,
  }
];
