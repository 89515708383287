import React from 'react';
import styled from 'styled-components';

import { S3 } from '../../constants/S3';

const Img = styled.img`
  float: left;
  margin-right: 15px;
  margin-bottom: 10px;
  width: 400px;

  @media (max-width: 1000px) {
    display: block;
    width: 90%;
    margin: 0 auto 20px;
    float: none;
  }
`;

const Civil = () =>
  <div>
    <Img src={`/images/practices/min/public.jpg`} />
    <p>
      Working in public service can be very daunting.
    </p>
    <p>
      As levels of responsibility rise, the challenges of policy design and delivery rise exponentially.
Policies have many stakeholders, each of whom is trying to shape outcomes in a particular way.
They also have many customers, often with a wide range of characteristics and interests to be
served. At times, given rapidly shifting political sands, the whole landscape can seem ambiguous
and threatening.
    </p>
    <p>
      Coaching can help individuals build confidence and resilience to operate successfully in a jungle
where many big beasts prowl. If you feel very lonely at times as you strive to do what you think
right, you are not the only one; talking to an independent, empathetic coach can help you to find
traction with colleagues with whom you must collaborate, and to believe in yourself.
    </p>
    <p>
      Understanding yourself and your value-system, building emotional resilience, and establishing
trusting relationships are key, and good coaching will develop you along all of these lines.
    </p>
  </div>;

export default Civil;
