import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import styled from "styled-components";
import { _center, _float } from "../../styles/display";
import { _lightGreyText, darkgrey } from "../../styles/colours";

const StyNav = styled.div`
  ${_lightGreyText}
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 100;
  border-bottom: 3px solid #fff;
  transition: 0.5s;
  background-color: ${darkgrey};
`;
// ${props => props.scroll > 100 && 'background-color: #222; color: #eee'}

// background: linear-gradient(#eee, transparent);

const StyNavLink = styled.div`
  font-size: 1.1rem;
  height: 60px;
  ${_center}
  ${_float}

  @media (max-width: 1200px) {
    font-size: 1rem;
  }
`;

const StyLogo = styled.img`
  height: 40px;

  &:hover {
    cursor: pointer;
  }
`;

const StyLink = styled(Link)`
  text-decoration: none;
  color: #eee;
`;

class DesktopNav extends Component {
  state = {};

  // state = {
  //   scroll: 0,
  //   open: false,
  // };
  //
  // componentDidMount() {
  //   window.addEventListener('scroll', this.handleScroll);
  // }
  //
  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // }
  //
  // handleScroll = () => this.setState({ scroll: window.scrollY });

  render() {
    return (
      <StyNav scroll={this.state.scroll}>
        <a onClick={() => scroll.scrollToTop()}>
          <StyLogo src="/images/home/logo light.svg" />
        </a>
        <ScrollLink
          activeClass="active"
          className="believe"
          to="We Believe"
          spy={true}
          smooth={true}
          duration={750}
        >
          <StyNavLink centertext={true}>We Believe</StyNavLink>
        </ScrollLink>
        <div>
          <StyLink to="/library">
            <StyNavLink>Library</StyNavLink>
          </StyLink>
        </div>
        <ScrollLink
          activeClass="active"
          className="test1"
          to="Our Practices"
          spy={true}
          smooth={true}
          duration={750}
        >
          <StyNavLink>Coaching Sectors</StyNavLink>
        </ScrollLink>
        {/* <ScrollLink
          activeClass="active"
          className="test1"
          to="Specialist Services"
          spy={true}
          smooth={true}
          duration={750}
        >
          <StyNavLink>Specialist Services</StyNavLink>
        </ScrollLink> */}
        <ScrollLink
          activeClass="active"
          className="test1"
          to="Our Coaches"
          spy={true}
          smooth={true}
          duration={750}
        >
          <StyNavLink>Meet the Team</StyNavLink>
        </ScrollLink>
        <div>
          <StyLink to="/our-ethics">
            <StyNavLink>Our Ethics</StyNavLink>
          </StyLink>
        </div>
        <div>
          <StyLink to="/contact-us">
            <StyNavLink>Contact Us</StyNavLink>
          </StyLink>
        </div>
      </StyNav>
    );
  }
}

export default DesktopNav;
