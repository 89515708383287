const CoachInfo = [
  {
    name: "Chris Whitehead",
    field: "Executive Coach",
    photo: "/images/coaches/min/chris-2.jpg",
    email: "chris@damflask-consulting.com",
    phone: "07951 853776",
    linkedin: "https://www.linkedin.com/in/chris-whitehead-b334ba20/",
    insta: "damflaskconsulting",
    bio: [
      `Chris is a Cambridge engineering graduate with 20 years’ experience of
      senior management and is accredited by the European Mentoring and Coaching Council (EMCC)
      as a Senior Practitioner. His passion for coaching originates from the same place
      as his successful career in business: he loves seeing others grow and develop.`,
      `He is particularly interested in the inner landscape of the individual
      and how that impacts on their personality and leadership style. He believes
      that a keen awareness of how things are right here, right now is the foundation
      for sustainable change. He supports his coachees in considering the quality
      rather than the quantity of their life.`,
      `His business coaching practice revolves around his experience as a
      managing director, business development director and programme manager. He is particularly interested
      in cultures in transition and is able to help individuals and teams synthesise
      a coherent strategy from complex information.`,
    ],
  },
  // {
  //   name: 'Nick Mather',
  //   field: 'Education Coach',
  //   photo: '/images/coaches/min/nick_m-min-2.jpg',
  //   email: 'nick@damflask-consulting.com',
  //   phone: '07588 508498',
  //   linkedin: 'https://www.linkedin.com/in/nickmatherpdc/',
  //   bio: [
  //     `Nick Mather has worked since 1997 in a variety of roles within Education,
  //     gaining experience as a classroom teacher, Head of Department, Faculty
  //     Head, Assistant Vice Principal and Director of Teaching and Learning.`,
  //     `He is a creative and inspiring individual who demonstrates high professional
  //     standards in all of his work. He has excellent communication skills and
  //     whether on a one to one basis or in front of larger groups he is able to
  //     capture and hold the interest of all through a mixture of humour, sincerity
  //     and his positive outlook on life.`,
  //     `By helping others to look through a different lens at life, he is able
  //     to help guide individuals and teams to make decisions that allow both
  //     themselves and others to be the very best they can.`,
  //   ],
  //   link: {
  //     text: `Nick coaches on the `,
  //     linkText: `DfE's Women Leading in Education programme`,
  //     link: `https://tscouncil.org.uk/coach-pledge/`
  //   }
  // },
  // {
  //   name: "Nick Walton",
  //   field: "Business Coach",
  //   photo: "/images/coaches/min/nick_w-min-2.jpg",
  //   email: "nickwalton@damflask-consulting.com",
  //   phone: "07769 642789",
  //   linkedin: "https://www.linkedin.com/in/nickwaltonsheffield/",
  //   bio: [
  //     `Nick built a career in sales, marketing and general management, and went
  //     on to work predominantly in the medical devices industry. His roles have
  //     encompassed both sales and marketing and have spanned large corporations
  //     as well as smaller European start up organisations.`,
  //     `Over close to thirty
  //     years Nick has continually demonstrated an ability to create and develop
  //     strong organisations and deliver results consistently. Nick believes that
  //     his success has been derived from creating cohesive, open minded teams
  //     and empowering them to excel.`,
  //     `He has particular expertise in situations
  //     involving a change in the role of a manager or leader, a performance
  //     development issue, a challenging relationship, an issue of work-life
  //     balance and/or a lack of satisfaction in work.`,
  //   ],
  // },
  // {
  //   name: 'John Elliott',
  //   field: 'Public Service Coach',
  //   photo: '/images/coaches/min/john-min-2.jpg',
  //   email: 'john@damflask-consulting.com',
  //   phone: '07932 192191',
  //   bio: [
  //     `John worked in the Civil Service for 30 years. He was the Chief Economist in the
  //     Department for Education and Skills, and later the Director for Social
  //     Science in the Home Office. He advised many Secretaries of State and
  //     Home Secretaries on the application of economics and research to policy problems.`,

  //     `John coaches to enhance the performance of leaders within the public sector,
  //     at any level in the organisation. He is particularly interested
  //     in what personal values bring to the leadership model, and in how they
  //     may be understood, developed and deployed to best advantage.`,

  //     `Leading an organisation can feel disorienting,
  //     stressful and often lonely. John aims to provide a supportive environment,
  //     placing coachees at the centre of the learning experience, providing clarity and
  //     insight to help them move along their chosen path with confidence.`,
  //   ],
  // },
  // {
  //   name: "Faye Booker",
  //   field: "Associate Business Coach",
  //   photo: "/images/coaches/min/faye-min.jpg",
  //   email: "fayebooker@damflask-consulting.com",
  //   phone: "07508 609045",
  //   linkedin: "https://www.linkedin.com/in/faye-booker-4b066484/",
  //   bio: [
  //     `Faye has worked both within the UK and internationally, where her leadership roles have focused on change and transformation.  She has almost 30 years’ experience of working, primarily within the public, and not for profit sector.`,
  //     `Faye is a curious, compassionate and observant individual who loves to get alongside individuals and organisations, to understand what may be holding you back, and explore how you can tap into the potential, which resides in all of us.`,
  //     `Faye’s eclectic coaching approach uses listening and creative exercises to provide:`,
  //     [
  //       "Space to think",
  //       "Time to explore",
  //       "Challenge to change",
  //       "Confidence to succeed",
  //     ],
  //   ],
  // },
  // {
  //   name: "Veronica Gallagher",
  //   field: "Executive Coach",
  //   photo: "/images/coaches/min/veronica-min-2.jpg",
  //   email: "veronica@damflask-consulting.com",
  //   phone: "07796 610279",
  //   linkedin: "https://www.linkedin.com/in/veronicagallagher/",
  //   bio: [
  //     `Veronica has held senior in – house counsel roles in global infrastructure organisations and more recently as a consultant with international law firms.`,
  //     `Her various roles involving change and growth both personally and professionally shape her practice as a coach. `,
  //     `With experience of coaching within professional services sectors Veronica has a particular interest in the benefits of coaching for the development of staff in the early stages of their career. `,
  //     `Veronica creates a space for coachees to be listened to and heard without judgement supporting them to find out what’s happening for them on a deeper level to help identify and understand their aspirations and motivations to enable change and development. `,
  //     `Veronica coaches from a philosophy that if we identify what is important to us about an aspiration and consider fully the outcomes of attainment then we are more likely to take steps to fulfilment. `,
  //   ],
  // },
  {
    name: "Lisa Spink",
    field: "Personal Development Coach",
    photo: "/images/coaches/lisa-2.jpg",
    email: "lisa@damflask-consulting.com",
    phone: "07714 216706",
    // linkedin: "https://www.linkedin.com/in/veronicagallagher/",
    bio: [
      "Lisa has worked in community development for over 20 years.  She has experience in youth work, volunteer coordination, student unions and community organisations, helping people to grow and develop.  She has a Graduate Diploma in Youth and Community work as well as a Diploma in Coaching.",
      "Her roles have focused on helping people build confidence and self-belief through enabling people to see their own strengths and abilities.  Lisa has been using coaching in community settings for many years and believes coaching can totally transform people and community organisations.  This is where her passion for coaching started as coaching enables her to walk alongside people and help them grow and develop at the pace that is right for them.",
      "Lisa’s coaching approach is a partnership that empowers people to take ownership over their own development.  She loves to train and support community and voluntary organisations to use coaching skills to enable their members, volunteers and staff to see real growth. ",
    ],
  },
  {
    name: "Greg Burke",
    field: "Executive Coach",
    photo: "/images/coaches/min/greg-2.jpg",
    email: "gregburkesheff@gmail.com",
    phone: "07557 752923",
    linkedin: "https://www.linkedin.com/in/greg-burke-b4013a27/",
    bio: [
      "Greg spent most of his career working as a Senior Civil Servant. He spent the last years of his career in the Higher Education sector. He is very motivated by seeing others achieve lasting change. This motivated him to get involved in coaching so that he could help more people achieve their potential.",
      "Greg is a curious individual who loves to get to know you to help identify what you want to achieve and what things are stopping you. His approach is focused on helping you to release the potential within to achieve lasting change.",
      "He is particularly interested in how personality styles can affect how we behave and how better understanding ourselves can really improve our overall success in all areas of our life.",
      "Greg is happy to use a wide range of techniques to: give you a safe space to explore; identify clear action; and  make change happen.",
    ],
  },
];

export default CoachInfo;
