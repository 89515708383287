import React from 'react';
import styled from 'styled-components';
import {
  darkgrey,
} from '../../styles/colours';

const StyContainer = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 450px) {
    padding-top: 50px;
  }
`;

const BackgroundImage = styled.div`
  background: white url("/images/home/flask2.jpg") no-repeat top center fixed;
  background-size: cover;
  filter: blur(3px) opacity(.75);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;

  @media (max-width: 1350px) {
    filter: blur(3px) opacity(0.5);
  }
`;

const StyTitle = styled.div`
  font-size: 1.4rem;
  color: ${darkgrey};
  width: 80%;
  max-width: 800px;
  padding-left: 10%;
  padding-bottom: 5%;
  text-align: left;
  z-index: 1;
  line-height: 1.35; 
`;

const H1 = styled.h1`
  @media (max-width: 340px) {
    font-size: 2.25rem;
  }
`;

const Header = () =>
  <StyContainer>
    <BackgroundImage />
    <StyTitle>
      <H1>Damflask Consulting</H1>
      <h3>Supporting personal insight, growth and fulfilment.</h3>
      <p>
      We are a network of coaches based in South Yorkshire.  Our coaching provides individuals with a
confidential space in which they can reflect, and examine situations from a fresh perspective. We
also work more widely within organisations to help foster productive team dynamics. Our ultimate
goal is to raise awareness and help clients take responsibility for decisions that lead to better
outcomes at work and in life.
      </p>
    </StyTitle>
  </StyContainer>;

export default Header;

// <Link><StyDown src='/images/home/down.svg' /></Link>
