import axios from "axios";
import React, { Component } from "react";
import { reduxForm } from "redux-form";
import styled from "styled-components";

import Message from "./Message";
import PageWrapper from "./PageWrapper";

import { HeaderBlockStyle } from "../styles/components";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 5%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.7);
`;

const HeaderBlock = styled.div`
  ${HeaderBlockStyle}
`;

const DetailsCol = styled.div`
  padding: 30px;
  width: 95%;
  max-width: 400px;
  margin: auto;
`;

const Detail = styled.div`
  margin: 15px 0 15px 0;
  @media (min-width: 600px) font-size: 1.2rem;
`;

const Icon = styled.i`
  margin-right: 15px;
`;

const MessageBoxCol = styled.div`
  padding: 30px;
  width: 100%;
  max-width: 600px;
`;

class Contact extends Component {
  state = {
    response: null,
    message: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  closeMessage = () => this.setState({ response: null, message: null });

  submitForm = (values) =>
    axios
      .post("/api/contact-form", values)
      .then(({ data }) => this.setState({ message: data, response: "success" }))
      .catch(({ response: { data } }) =>
        this.setState({
          message: `We're sorry but we had a problem sending your message. Please use one of the other contact methods available to submit your enquiry.`,
          response: "fail",
        })
      );

  render() {
    const { response, message } = this.state;
    const { handleSubmit } = this.props;

    return (
      <PageWrapper>
        {response === "success" && (
          <Message
            message={message}
            response={response}
            close={this.closeMessage}
          />
        )}
        {response === "fail" && (
          <Message
            message={message}
            response={response}
            close={this.closeMessage}
          />
        )}
        <HeaderBlock>
          <h1>Contact Us</h1>
        </HeaderBlock>
        <Container>
          <DetailsCol>
            <h2>Contact details</h2>
            <p>
              If you'd like to organise a coaching session with one of our
              expert coaches, arrange a coaching retreat or if you have any
              questions about the services provided by Damflask Consulting,
              please don't hesitate to get in touch via any of the media set out
              below.
            </p>
            <Detail>
              <Icon className="fas fa-phone-square" />
              <span>Tel: +44 7951 853 776</span>
            </Detail>
            <Detail>
              <Icon className="fas fa-envelope-square" />
              <span>info@damflask-consulting.com</span>
            </Detail>
          </DetailsCol>
          {/* <MessageBoxCol>
            <h2>Enquiry Form</h2>
            <BasicForm
              fields={CONTACT_FORM}
              submit="Send"
              onSubmit={this.submitForm}
              handleSubmit={handleSubmit}
            />
          </MessageBoxCol> */}
        </Container>
      </PageWrapper>
    );
  }
}

export default reduxForm({
  form: "contact",
})(Contact);
