import React, { Component } from "react";
import styled, { createGlobalStyle } from "styled-components";

import Header from "../components/home/Header";
import Tiles from "../components/home/Tiles";
import SpecialistServices from "../components/home/SpecialistServices";
import Nav from "../components/nav/Nav";
import Testimonials from "../components/testimonials/Home";
import PracticeGrid from "../components/home/PracticeGrid";
import { TopLayer, BottomLayer } from "../constants/Pages";

import { _lightBlueBackground } from "../styles/colours";

const Container = styled.div`
  background-color: #f5f5f5;
`;

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Raleway';
  }

  h1, h2, h3, h4 {
    font-weight: lighter
  }

  h1 {
    letter-spacing: 5px;
  }
`;
class HomeContainer extends Component {
  render() {
    return (
      <Container>
        <GlobalStyle />
        <Nav />
        <Header />
        <Tiles tiles={TopLayer} />
        <Testimonials />
        <PracticeGrid />
        {/* <SpecialistServices /> */}
        <Tiles tiles={BottomLayer} reverse />
      </Container>
    );
  }
}

export default HomeContainer;
